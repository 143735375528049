	body{
	  background: #62AFF9;
	}

	.box{
	  position: relative;
	  margin: auto;
	  display: block;
	  margin-top: 8%;
	  width: 600px;
	  height: 420px;
	  background: none;

	}

	.head{
	  position: absolute;
	  top:16.5%;
	  left: 25%;
	  width: 50%;
	  height: 67%;
	  background: #FFFFFF;
	  border-radius: 50%;
	}
	
	.eye-left{
	  position: absolute;
	  top:39%;
	  left: 28%;
	  width: 7%;
	  height: 7%;
	  background: #000000;
	  border-radius: 50%;
	  z-index: 1;
	}
	
	.eye-right{
	  position: absolute;
	  top:39%;
	  right: 28%;
	  width: 7%;
	  height: 7%;
	  background: #000000;
	  border-radius: 50%;
	  z-index: 1;
	}
	
	.nose{
	 position: absolute;
	 top:19%;
	 left: -5%;
	 width: 110%;
	 height: 110%;
	 -webkit-clip-path: ellipse(41% 25% at 51% 54%);
	 clip-path: ellipse(41% 25% at 51% 54%);
	 background: #FEE9E8;
	 z-index: 1;
	
	}

	.nostril-left{
	  position: absolute;
	  top:75%;
	  left: 20%;
	  width: 5%;
	  height: 8%;
	  background: #000000;
	  border-radius: 50%;
	  z-index: 2;

	}

	.nostril-rigth{
	  position: absolute;
	  top:75%;
	  left: 75%;
	  width: 5%;
	  height: 8%;
	  background: #000000;
	  border-radius: 50%;
	  z-index: 2;
	
	}

	.pupil-top-left{
		position: absolute;
		top:40%;
		left: 29%;
		width: 3%;
		height: 3%;
		background: #FFFFFF;
		border-radius: 50%;
		z-index: 3;
	
	}

	.pupil-top-rigth{
		position: absolute;
		top:40%;
		left:68%;
		width: 3%;
		height: 3%;
		background: #FFFFFF;
		border-radius: 50%;
		z-index: 3;
	
	}
	
	.blush-lef{
		position: absolute;
		top:40%;
		left: 20%;
		width: 15%;
		height: 15%;
		-webkit-clip-path: ellipse(23% 10% at 50% 53%);
		clip-path: ellipse(23% 10% at 50% 53%);
		background: #FEE9E6;
		z-index: 1;
	}
	
	.blush-rigth{
		position: absolute;
		top:40%;
		left: 65%;
		width: 15%;
		height: 15%;
		-webkit-clip-path: ellipse(23% 10% at 50% 53%);
		clip-path: ellipse(23% 10% at 50% 53%);
		background: #FEE9E6;
		z-index: 4;
	}
	
	.hair-black{
		position: absolute;
		top:10%;
		left: 50%;
		width: 45%;
		height: 45%;
		background:#343a40;
		border-radius: 50%;
		z-index: 1;
		opacity: 40;
		
	}
	
	.horn-left{
		position: absolute;
		top:-6%;
		left: 20%;
		width: 5%;
		height: 15%;
		border-radius: 50%;
		z-index: -1;
		background:#997b64;
		transform:rotate(-30deg)
	

	}
	
	.horn-rigth{
		position: absolute;
		top:-7%;
		left: 75%;
		width: 5%;
		height: 15%;
		border-radius: 50%;
		z-index: -1;
		background:#997b64;
		transform:rotate(-148deg)
	}
	
	
	.ear-left{
		position: absolute;
		top:7%;
		left: 1%;
		width: 10%;
		height: 25%;
		border-radius: 50%;
		z-index: -1;
		background:#343a40;
		opacity: 40;
		transform:rotate(-290deg)
		
	
	}
	
	.ear-rigth{
		position: absolute;
		top:5%;
		left: 89%;
		width: 10%;
		height: 25%;
		border-radius: 50%;
		background:#FFFFFF;
		transform:rotate(-255deg)
	}

  .counter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .count-text {
    font-weight: bold;
    font-size: 2rem;
  }

  .btn {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 2rem;
    border: #000000 1px solid;
    background-color: transparent;
    border-radius: 1rem;
    width: 5rem;
    height: 5rem;
  }